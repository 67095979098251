import React from 'react'
import { Row, Col } from 'react-bootstrap'

import XODarrellSmith from './XODarrellSmith'
import COBTravisSkipper from './COBTravisSkipper'
import COChadGuillerault from './COChadGuillerault'

const CrewSection = () => (
    <>
    <COChadGuillerault />
    <XODarrellSmith />
    <COBTravisSkipper />
</>
)

export default CrewSection