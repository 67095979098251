import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import {
    Row,
    Col
} from 'react-bootstrap'
import * as styles from './Submarine.module.css'

const COChadGuillerault = () => {
    const data = useStaticQuery(graphql`
    {
      ChadGuillerault: file(relativePath: { eq: "COBBbluesCovered-Guillerault.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 330, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
        <Row style={{
            backgroundColor: '#426690',
            borderRadius: '5px',
            padding: '2rem',
        }}>
            <Col sm={9} className={styles.leadershipReverse}>
            <h2>Commanding Officer</h2>
            <h3>Commander Chad J. Guillerault</h3>
            <hr style={{borderColor: 'rgba(255, 255, 255, 0.25)'}}/>
            
            <p>CDR Guillerault is a native of Biddeford, ME. He is a 2007 graduate of SUNY Maritime College where he earned a Bachelor of Science in Electrical Engineering. He also holds a Master of Science in Engineering Management from Old Dominion University.</p>
            <p>His operational assignments include service in both attack and ballistic missile submarines including service as Division Officer on USS NEBRASKA (GOLD), Combat Systems Officer on USS VIRGINIA, and Executive Officer on USS OHIO (GOLD). During these tours he completed five strategic deterrent patrols, two North Atlantic deployments, and three deployments to the Pacific.</p>
            <p>Staff assignments include instructor duty at Trident Training Facility Bangor, WA; Operations Officer at Submarine Squadron 19; and Capability Development Department Head at Undersea Warfighting Development Center, Tactical Development Group.</p>
            <p>CDR Guillerault is authorized to wear the Meritorious Service Medal (two awards), Navy Marine Corp Commendation Medal (seven awards), and Navy Marine Corp Achievement Medal (one award). He has served on Navy teams that have been awarded various awards to include Meritorious Unit Commendations and Battle Efficiency “E” Awards.</p>
            <p>CDR Guillerault resides in Chester, CT with his beautiful wife, the former Victoria Macdonald Smith of Knutsford, England. They have two lovely children together, Emily and Thomas.</p>

            </Col>
            <Col md={3}>
                <Img fluid={data.ChadGuillerault.childImageSharp.fluid} alt="Commander Chad Guillerault" className="rounded" />
            </Col>
        </Row>
    </>
  )
}

export default COChadGuillerault